// libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Utils
import { formatNumber } from '../../utils/utils';

// utils components
import Image from '../../utils/components/Image';

function Status({ date }) {
  return <span className="status sz"> {date} </span>;
}

function WebinarCard({ _id, imagePath, title, name, details, date }) {
  return (
    <div className="event-card">
      <Status date={date} />

      <div className="top-bar">
        <div className="image">
          <Image src={imagePath} />
        </div>
        <div
          style={{
            maxWidth: '250px',
            minHeight: '160px',
            textAlign: 'left'
          }}
          className="name"
        >
          <h4>{name}</h4>
          <small>{title}</small>

          <h5
            style={{
              maxWidth: '250px',
              textAlign: 'left',
              margin:0,
              paddingTop: '20px'

            }}
          >
            {details}
          </h5>
          <h5  style={{
              maxWidth: '250px',
              textAlign: 'left',
              padding: '0px',
              margin:0
            }}>
          KEC International Limited.
          </h5>
        </div>
      </div>
      <div className="details">
        {/* <div
          className="info"
          style={{
            textAlign: 'center'
          }}
        >
          <small>{formatNumber(registrations)}</small>
          <span>
            <i className="fab fa-redhat" />
            Participants
          </span>
        </div> */}

        {/* <div
          className="info"
          style={{
            textAlign: 'center'
          }}
        >

          <small>{formatNumber(ytViews)}</small>
          <span>
            <i className="fab fa-youtube" /> Views
          </span>

        </div> */}

        <Link to={`/downloads/id/${_id}`} className="btn-primary small round ">
          See more <i className="right fa fa-arrow-right" />{' '}
        </Link>
      </div>
    </div>
  );
}

export default WebinarCard;
