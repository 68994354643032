// libraries
import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import axios from 'axios';

// components
import WebinarCard from './WebinarCard';
import logoKJ from '../../public/logoKJCropped.png';
import CAMSLogo from '../../public/CAMSLogo.png';
import KEC from '../../public/KEC.png';

function Webinars() {
  const [events, setEvents] = useState( []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get('https://kec-backend.herokuapp.com/getAllEvents')
      .then(response => {
        setEvents(response.data.events);
      })
      .catch(err => {});
    // setwebinar(webinarDetails[id]);
    setIsLoading(false);
  }, []);
  if (isLoading)
    return (
      <div className="event-details">
        <div className="loading">Loading</div>
      </div>
    );

  return (
    <div className="webinars">
      <Helmet>
        <title> {`Downloads - KEC`}</title>
        <meta
          name="description"
          content={'Downloads - KEC'}
        />
      </Helmet>
      <img src={KEC} width="20%"></img>
      {/* <div className="socials">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/c/KJSIEITofficial"
          className="social-icon icon-only yt"
        >
          <i className="fab fa-youtube" />
        </a>
        <a
          href="https://www.facebook.com/kjsieitofficial"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon icon-only li"
        >
          <i className="fab fa-facebook-square" />
        </a>
        <a
          href="https://www.instagram.com/kjsieit_22"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon icon-only gi"
        >
          <i className="fab fa-instagram" />
        </a>
      </div> */}
      <h1 style={{ textAlign: 'center', color: '#1494d4' }} className="info">
        Downloads
      </h1>

      <div className="container">
        {events.map(webinar => (
          <WebinarCard key={webinar._id} {...webinar} />
        ))}
      </div>
    </div>
  );
}

export default Webinars;
