// libraries
import React, { useContext, useState, useEffect, Redirect } from 'react';
import { Route } from 'react-router-dom';

// react hooks
import { GlobalContext } from './../state-manager/globalState';

// components
import Home from './main/Home';
import Predictions from './main/Webinars';
import WebinarDetails from './main/WebinarDetails';
import Resource from './main/Resource';
import About from './main/About';

function Main() {
  // react hooks
  const { setOffline, is_offline } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);

  // fetch the data when the component did mount
  useEffect(() => {
    setIsLoading(false);

    setOffline(!navigator.onLine);
    window.addEventListener('offline', () => {
      setOffline(true);
    });
    window.addEventListener('online', () => {
      setOffline(false);
    });
  }, []);

  if (isLoading)
    return (
      <main>
        {' '}
        <div className="loading">Loading</div>{' '}
      </main>
    );

  return (
    <main>
      <Route exact path="/" component={Home} />
      <Route exact path="/downloads" component={Predictions} />
      <Route exact path="/downloads/id/:id" component={WebinarDetails} />

      {/* <Route exact path="/about" component={About} /> */}
      {/* <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://in.linkedin.com/in/dr-radhika-kotecha-aa827951"
      >
        <div className={`hash${is_offline ? " hide" : ""}`}>
          <h3>{"{ Devoir }"}</h3>
          <h4>
            <i className="fab fa-linkedin" /> Dr. Radhika Kotecha
          </h4>
          <h4>
            Head - Department of<br></br> Information Technology{" "}
          </h4>
        </div>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://in.linkedin.com/in/parthdmaniar"
      >
        <div className={`hash2${is_offline ? " hide" : ""}`}>
          <h3>{"{ Developed by }"}</h3>
          <h4>
            <i className="fab fa-linkedin" /> Mr. Parth Maniar
          </h4>

          <h4>
            Student - Department of<br></br> Information Technology
          </h4>
        </div>
      </a> */}
      <div className={`space`}> </div>
      <div className={`notifier${true ? ' show' : ''}`}>
        <p>
          Developed by:{' '}
          <a
            style={{ textDecoration: 'none', color: 'white' }}
            href="https://www.linkedin.com/company/vrtaxconsultants"
          >
            {' '}
            <i className="fab fa-linkedin" /> V. R. Tax Consultants
          </a>
          <br></br>
          {/* Devoir:
          <a
            style={{ textDecoration: 'none', color: 'white' }}
            href="https://in.linkedin.com/in/dr-radhika-kotecha-aa827951"
          >
            {' '}
            <i className="fab fa-linkedin" /> Dr. Radhika Kotecha, Head -
            Department of I.T.
          </a> */}
        </p>
      </div>
    </main>
  );
}

export default Main;
