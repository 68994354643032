// libraries
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// google analytics

import { createBrowserHistory } from 'history';
// react hooks (state management  )

import { GlobalProvider } from './state-manager/globalState';

// styles
import './App.css';
import './Responsive.css';
import './fontawesome/css/all.css';

// components
import Main from './components/Main';
import Aside from './components/Aside';

// react routers history
const history = createBrowserHistory();

function App() {
  return (
    <GlobalProvider>
      <div className="App">
        <Router history={history}>

            <Aside />
            <Main />
 
        </Router>
      </div>
    </GlobalProvider>
  );
}

// <Footer />

export default App;
